// ==========================================================================
// Author Molecule
// ==========================================================================

.m-author {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid palette(greyMid); // @todo use closest palette colour
  border-radius: 5px;
}

.m-author__img {
  display: block;
  max-width: 200px;
}

.m-author__links {
  margin: 0 0 0 rem-calc(-15);
  list-style: none;
}

.m-author__name {

}

.m-author__role {
  font-size: ms(0);
}
