// ==========================================================================
// Header Molecule
// ==========================================================================

.m-header__wrapper {
  background-color: palette("white");
  z-index: 999999;
}

.m-header__wrapper--expanded {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto
}

.m-header {
  border-bottom: 2px solid palette("grey");

  @include respond-to(medium) {
    padding-top: spacing(double);
  }

  .btn {
    height: 60px;
    width: 60px;
    border: transparent;
    border-left: 2px solid palette('grey');
    border-radius: 0;
    margin: 0;

    @include respond-to(medium) {
      border: transparent;
    }
  }
}

.m-header--mini {
  .btn--is-active {
    background-color: palette("primary");
    color: palette("white");
  }
}

.m-header--mini__logo {
  height: 100%;
}
