// ==========================================================================
// Menu Molecule
// ==========================================================================

.m-menu {
  /* A re-usable nav component */
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  /* Reset list styles */
  .nav-item {
    font-size: 16px;
    margin: 0;

    &,
    a {

      &,
      &:visited { /* Required to overrule design sys base */
        color: palette('black');
        text-decoration: none;
      }
    }
  }

  /* Style top-level items */
  .nav__level-1 {

    .nav-item {
      display: flex;
      height: 5rem;

      a {
        border-bottom: 2px solid transparent;
        display: flex;
        height: 100%;
        align-items: center;
        padding: .5rem;
      }
      a:hover,
      a:focus {
        color: palette("aqua");
      }
      a:hover,
      &--active a {
        border-bottom: 2px solid palette("aqua");
      }
    }

    .nav-toggle {
      margin: .125rem 0 0 -.25rem;

      &.active {
        margin:  0 0 .125rem -.25rem;
        transform: rotate(180deg);
      }
    }
  }
}

/* Style second-level items */
.nav__level-2 {
  border-bottom: 1px solid palette("grey");
  padding-bottom: .75rem;

  .nav {
    padding-right: 5.175rem;
  }

  .nav-item a {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      color: palette('primary');
      text-decoration: underline;
    }
  }
}
