// ==========================================================================
// Alert Molecule
// ==========================================================================

.m-alert {
  position: relative;
  padding: spacing(base);
  background-color: palette("primary");
  color: palette("white");
  font-size: ms(2);
  font-weight: 700;

  @include respond-to(medium) {
    padding: spacing(double triple);
  }
}

.m-alert--compact {
  max-width: 20rem;
  padding: spacing(double);
}

.m-alert--btn {
  position: absolute;
  top: spacing(base);
  right: spacing(base);
  color: currentColor;
  font: inherit;
}
