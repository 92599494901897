// // Global Tools
// Design tokens

@import "~shu-frontend-design-system/assets/styles/design/colours.map";
@import "~shu-frontend-design-system/assets/styles/design/breakpoints.map";
@import "~shu-frontend-design-system/assets/styles/design/spacing.map";
@import "~shu-frontend-design-system/assets/styles/tools";

// Add colours not found in base design system
// @todo refactor these into base?
$additionalColours: (
  "greyLight": #f7f7f7,
  "greyMid": #d1d1d1,
  "aqua": #00b3bf,
);




// load design tokens into sass
@include palette-load(map-merge($colours-map, $additionalColours));
@include breakpoint-load($breakpoints-map);
@include spacing-load($spacing-map);

// Custom tools here

@import "~shu-frontend-design-system/assets/styles/normalize";

// Layout
@import "~shu-frontend-design-system/assets/styles/layout";

// Custom Layout here

// Atoms

// // All atoms
@import "../../components/atoms/document/document";
@import "~shu-frontend-design-system/assets/styles/atoms/avatar/avatar";
@import "~shu-frontend-design-system/assets/styles/atoms/burger/burger";
@import "~shu-frontend-design-system/assets/styles/atoms/chevron/chevron";
@import "~shu-frontend-design-system/assets/styles/atoms/pill/pill";
@import "~shu-frontend-design-system/assets/styles/atoms/headings/headings";
@import "~shu-frontend-design-system/assets/styles/atoms/island/island";
@import "~shu-frontend-design-system/assets/styles/atoms/lede/lede";
@import "~shu-frontend-design-system/assets/styles/atoms/hr/hr";
@import "~shu-frontend-design-system/assets/styles/atoms/figure/figure";
@import "~shu-frontend-design-system/assets/styles/atoms/link/link";
@import "~shu-frontend-design-system/assets/styles/atoms/meta/meta";
@import "~shu-frontend-design-system/assets/styles/atoms/paragraph/paragraph";
@import "~shu-frontend-design-system/assets/styles/atoms/micro/micro";
@import "~shu-frontend-design-system/assets/styles/atoms/btn/btn";
@import "~shu-frontend-design-system/assets/styles/atoms/btn-group/btn-group";
@import "~shu-frontend-design-system/assets/styles/atoms/nav-item/nav-item";
@import "~shu-frontend-design-system/assets/styles/atoms/list/list";
@import "~shu-frontend-design-system/assets/styles/atoms/cite/cite";
// @import "~shu-frontend-design-system/assets/styles/atoms/page-title/page-title";
@import "~shu-frontend-design-system/assets/styles/atoms/video/video";
@import "~shu-frontend-design-system/assets/styles/atoms/image/image";
@import "~shu-frontend-design-system/assets/styles/atoms/shadow/shadow";
@import "~shu-frontend-design-system/assets/styles/atoms/close/close";
@import "~shu-frontend-design-system/assets/styles/atoms/forms/forms";
@import "~shu-frontend-design-system/assets/styles/atoms/status-indicator/status-indicator";

// // Custom atoms here
@import "../../components/atoms/logo/logo";

// Molecules
@import "../../components/molecules/alert/alert";
@import "../../components/molecules/author/author";
@import "../../components/molecules/cta/cta";
@import "../../components/molecules/footer/footer";
@import "../../components/molecules/header/header";
@import "../../components/molecules/hero/hero";
@import "../../components/molecules/inline-image/inline-image";
@import "../../components/molecules/menu/menu";
@import "../../components/molecules/menu-mobile/menu-mobile";
@import "../../components/molecules/page-header/page-header";
@import "../../components/molecules/pagination/pagination";
@import "../../components/molecules/quote/quote";
@import "../../components/molecules/search-box/search-box";
@import "../../components/molecules/sidebar-nav/sidebar-nav";
@import "../../components/molecules/slider/slider";
@import "../../components/molecules/stat/stat";
@import "../../components/molecules/teaser/teaser";

// Utilities
@import "~shu-frontend-design-system/assets/styles/utils";
@import "./utilities/font";
@import "./utilities/margin";
@import "./utilities/max-width";
@import "./utilities/radius";

// @todo: remove this when design system base updates are merged
// See: https://github.com/madebykind/shu-design-system-base/pull/8
.m-footer.u-inverse a {
  @include link-hover() {
    text-decoration: underline;
  }
}


// Custom Utilities here

.alignleft {
  width: 100%;
  height: auto;

  @include respond-to(medium) {
    width: auto;
    margin-top: spacing(15);
    margin-right: spacing(15);
    margin-bottom: spacing(15);
    float: left;
  }
}

.alignright {
  width: 100%;
  height: auto;

  @include respond-to(medium) {
    width: auto;
    margin-top: spacing(15);
    margin-bottom: spacing(15);
    margin-left: spacing(15);
    float: right;
  }
}

.aligncenter {
  width: 100%;
  height: auto;

  @include respond-to(medium) {
    display: block;
    width: auto;
    margin-top: spacing(15);
    margin-right: auto;
    margin-bottom: spacing(15);
    margin-left: auto;
    clear: both;
  }
}

.social-links-h {
  margin: 0;
}

.social-link {
  display: inline-block;
  margin-left: .9375rem;
}

.social-link__inner {
  display: flex;
  width: 2.625rem;
  height: 2.625rem;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: rem-calc(5);
  color: palette("black") !important;
  font-size: rem-calc(19);

  &:hover,
  &:active {
    background: #e6e6e6;
  }
}

.wrapper--thin {
  margin: 0 auto;
  max-width: 100%;

  @include respond-to(medium) {
      max-width: rem-calc(960);
      padding-left: rem-calc(15);
      padding-right: rem-calc(15);
  }
}

@include respond-to(medium) {
  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }
}
