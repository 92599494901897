// ==========================================================================
// Logo Atom
// ==========================================================================

.logo {
  position: relative;
  height: 60px;
  display: flex;
  max-width: 80px;

  @include respond-to(medium) {
    height: auto;
    max-width: none;
    width: 130px;
  }

  &-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
