// ==========================================================================
// Search Box Molecule
// ==========================================================================

.m-search-box {
  position: relative;

  @include respond-to(medium) {
    height: 5rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input {
    border: 1px solid palette('black');
    width: 100%;

    &::placeholder {
      color: palette('greyMid');
    }

    @include respond-to(medium) {
      padding: 0.9375rem 3.125rem 0.6875rem 0.6875rem;
    }
  }
}

.m-search-box--btn {
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
}

.m-search-box--btn-search {
  right: 0;
}

.m-search-box--btn-close {
  left: 100%;
}
