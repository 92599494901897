// ==========================================================================
// Max-width Utility
// ==========================================================================

.u-max-w-sm {
  max-width: 20rem;
}


.u-max-w-lg {
  max-width: 51rem;
}
