// ==========================================================================
// Stat Molecule
// ==========================================================================

.m-stat {

}

.m-stat__img {

}

.m-stat__stat {
  font-size: ms(7);
  font-weight: 700;
}

.m-stat__text {
  font-size: ms(0);
  line-height: 1.6;
}
