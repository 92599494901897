// ==========================================================================
// Slider Molecule
// ==========================================================================
.m-slider {
  max-width: 800px;
  position: relative;
  z-index: 1;
}

.m-slider__caption {
  font-size: ms(-1);
}

.m-slider__controls {
  position: absolute;
  z-index: 3;
  top: spacing(double);
  right: spacing(double);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: palette("primary");
  color: palette("white");
  line-height: 1;

  .chevron {
    &::before {
      top: 0;
    }
  }

  &:focus-within *::-moz-focus-inner {
    border: 0;
  }
}

.m-slider__control {
  width: 30px;
  height: 30px;
  padding: 15px 0 20px;
  color: palette("white");

  @include link-hover() {
    background-color: white;
    color: palette("primary");
  }
}

.m-slider__count {
  padding: 15px;
  font-family: "Roboto";
  font-size: ms(-2);
}

.m-slider__img {
  width: 100%;
}

.m-slider__thumb {
  width: 100%;
  opacity: 0.6;
  transition: opacity 0.25s ease;

  @include link-hover() {
    cursor: pointer;
  }

  .tns-nav-active & {
    cursor: default;
    opacity: 1;
  }
}

.tns-item {
  margin-top: 0;
}
