// ==========================================================================
// Page Header Molecule
// ==========================================================================

.m-page-header {
  margin-top: 1em;
  margin-bottom: .5em;
}

.m-page-header--title {
  font-size: ms(6);
  font-weight: 500;
  line-height: 1.06;
  margin-bottom: rem-calc(20);

  @include respond-to(medium) {
    font-size: ms(10);
  }
}

.m-page-header--meta {
  list-style: none;
  padding-bottom: 0;
  margin: 0;

  .meta {
    display: inline;
    font-size: ms(-2);
    font-weight: 900;
    padding: 0;
  }
}

.m-page-header--share {
  display: inline-flex;
  justify-content: end;
}

.m-share-list {
  display: inline-flex;
  margin: 0;
  list-style: none;
}

.m-share-list__item {
  margin: 0;
}

.share-link {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border: 2px solid palette(greyLight);
  text-decoration: none;
}
