/**
 * Document styles
 */
html {
  color: white;
  background: palette("black");
  min-height: 100%;
}

body {
  color: palette("black");
  background: white;
}
