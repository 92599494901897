// ==========================================================================
// Menu Molecule
// ==========================================================================

.m-menu--mobile {
  background-color: palette("white");

  .nav {
    width: 100%;
    padding: 1rem 0 0;
    border-bottom: 1px solid palette("grey");
    margin: 0;
  }

  .nav-item {
    padding: 0;
    border-top: 1px solid palette("grey");
    font-size: 16px;
    margin: 0;

    &:first-child {
      border: 0;
    }

    a {
      color: palette('black');
      display: block;
      width: 100%;
      padding: 0.5rem 0;
      text-decoration: none;
      text-indent: 1.5rem;

      &:focus,
      &:hover {
        outline: none;
        text-decoration: underline;
      }
    }

    &.nav-item--active > a {
      text-decoration: underline;
    }
  }

  .nav__level-1 {
    font-family: "Roboto";
    font-weight: 700;
  }

  .nav__level-2 {
    border-top: 1px solid palette("grey");
    padding: 0;

    .nav-item {

      a {
        font-family: "Roboto Slab";
        font-weight: 300;
        text-indent: 2rem;
      }
    }
  }

  .nav-inner {
    display: flex;
    justify-content: space-between;
  }

  .nav-toggle {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.3px;
    text-transform: uppercase;

    svg.active {
      transform: rotate(180deg);
    }
  }
}
