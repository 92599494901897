// ==========================================================================
// Inline Image Molecule
// ==========================================================================

.m-inline-image {}

.m-inline-image__img {
  margin-bottom: spacing(base);
}

.m-inline-image--reversed {
  flex-direction: row-reverse;
}
