// ==========================================================================
// Sidebar Nav Molecule
// ==========================================================================

.m-sidebar-nav {
  + .m-sidebar-nav {
    margin-top: spacing(double);
  }
}

.m-sidebar-nav__heading {
  padding: spacing(half base);
  border-bottom: 1px solid palette(greyMid);
  margin: 0;
  background-color: palette("grey");
  font-size: ms(-1);
}

.m-sidebar-nav__list {
  margin: 0;
  list-style: none;
}

.m-sidebar-nav__list-item {
  padding: spacing(half base);
  border-top: 1px solid palette(greyMid);
  margin: 0;
  font-size: ms(-2);
}
