// ==========================================================================
// Blockquote Molecule
// ==========================================================================

.m-blockquote {
  max-width: 38rem;
  margin: auto;
}

.m-blockquote--compact {
  max-width: 21rem;
}

.m-blockquote__name {
  font-size: ms(0);
}

.m-blockquote__quote {
  margin: 0;
  line-height: 1.38;
}

.m-blockquote__role {

}
