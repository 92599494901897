// ==========================================================================
// Pagination Molecule
// ==========================================================================

.m-pagination {
  margin: 1.5rem 0;
}

.m-pagination--list {
  display: flex;
  list-style: none;
}

.m-pagination--item {
  align-items: center;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  line-height: 1;
  width: 2.5rem;
}

a.m-pagination--item  {
  transition: all .2s ease;

  &:focus,
  &:hover {
    background-color: palette('greyMid');
  }
}
