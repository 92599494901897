// ==========================================================================
// Hero Molecule
// ==========================================================================

.m-hero {

}

.m-hero__intro {
  font-size: ms(0);
  line-height: 1.4;
}
