// ==========================================================================
// Author Molecule
// ==========================================================================

.m-cta {
  border: 1px solid palette(greyMid); // @todo use closest palette colour
  border-radius: 5px;
}

.m-cta__img {
  display: block;
}

.m-cta__link {
  margin: 0 0 0 rem-calc(-15);
  font-family: 'Roboto';
  font-weight: 700;
}

.m-cta__text {
  font-size: ms(0);
}
