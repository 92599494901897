// ==========================================================================
// Teaser Molecule
// ==========================================================================

.m-teaser {}
.m-teaser__date {}
.m-teaser__description {}
.m-teaser__image {}
.m-teaser__link {}
.m-teaser__meta {}
.m-teaser__title .link--plain {
  text-decoration: underline;
}
